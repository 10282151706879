import React from 'react';

import { required } from '../../util/validators';
import { FieldTextInput } from '..';

import css from './CageDimensions.module.css';

const InputDimension = props => (
  <div className={css.dimensionContainer}>
    <div className={css.dimensionInput}>
      <FieldTextInput {...props} />
      <span>ft.</span>
    </div>
    <span>{props.dimension}</span>
  </div>
);

const CageDimensions = props => {
  const {
    fieldConfig: { saveConfig },
  } = props;
  const { label, isRequired, requiredMessage = 'required' } = saveConfig || {};
  const validateField = isRequired ? required(requiredMessage) : null;

  return (
    <div className={css.dimensions}>
      <label>{label}</label>
      <InputDimension
        id="pub_cageDimension.width"
        name="pub_cageDimension.width"
        className="cageDimension"
        placeholder="0"
        type="number"
        dimension="Width"
        validate={validateField}
      />
      <InputDimension
        id="pub_cageDimension.length"
        name="pub_cageDimension.length"
        className="cageDimension"
        placeholder="0"
        type="number"
        dimension="Length"
        validate={validateField}
      />
      <InputDimension
        id="pub_cageDimension.height"
        name="pub_cageDimension.height"
        className="cageDimension"
        placeholder="0"
        type="number"
        dimension="Height"
        validate={validateField}
      />
    </div>
  );
};

export default CageDimensions;
